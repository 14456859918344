@import 'https://fonts.googleapis.com/css?family=Roboto:400,500';

.default-form {
    padding-top: 30px;

    .default-form-group {
        position: relative;
        margin-bottom: 30px;
        width: 100%;

        .default-label {
            color: RGBA(76, 76, 76, 1.00);
            font-size: 1.1em;
            font-weight: normal;
            position: absolute;
            pointer-events: none;
            left: 10px;
            top: 8px;
            transition: 0.2s ease all; 
            -moz-transition: 0.2s ease all; 
            -webkit-transition: 0.2s ease all;
            font-family: 'Roboto';
        }

        .default-input {
            border: none;
            box-shadow: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 0;
            color: rgba(0, 0, 0, 0.6);
            background-color: transparent;
            width: 100%;
            font-family: 'Roboto';
            color: rgba(0, 0, 0, 0.8) !important;

            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
                transition: background-color 5000s ease-in-out 0s;
            }
        }

        .default-input:focus ~ .default-label, .default-input:valid ~ .default-label, .default-label.fixed {
            color: rgba(0, 0, 0, 0.7);
            top: -15px;
            left: 5px;
            font-size: 14px;
            font-weight: 600;
        }

        .bar {
            position: relative;
            display: block;
            width: 100%;

            &:before, &:after {
                content:'';
                height:2px; 
                width:0;
                bottom:1px; 
                position:absolute;
                background: rgba(0, 0, 0, 0.2); 
                transition:0.2s ease all; 
                -moz-transition:0.2s ease all; 
                -webkit-transition:0.2s ease all;
            }

            &:before {
                left: 50%;
            }

            &:after {
                right: 50%;
            }
        }

        .default-input:focus ~ .bar:before, .default-input:focus ~ .bar:after {
            width: 50%;
        }

        .default-input:focus ~ .highlight {
            -webkit-animation: inputHighlighter 0.3s ease;
            -moz-animation: inputHighlighter 0.3s ease;
            animation: inputHighlighter 0.3s ease;
        }

        &.has-warning {
            .bar {
                background-color: rgba(250, 250, 0, 0.1);
            }
        }
    }
}